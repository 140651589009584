import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


export const GraphicPortfolio = ()=> {
    return (
<>
            <div className='row d-flex justify-content-center'>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img style={{ height: '10rem', backgroundPosition: 'center', backgroundSize: 'cover' }} variant="top" src="https://live.staticflickr.com/65535/52684490541_a666501354_b.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>La Ventanita Logo</Card.Title>
                            <Card.Text>
                                Renovación de Logo e imagen para restaurante La Ventanita.
                            </Card.Text>
                            <Button className='btn-cards' target='_blank' href='https://live.staticflickr.com/65535/52702346893_5fe5f8641b_b.jpg'>Ver Diseño</Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img style={{ height: '10rem', backgroundPosition: 'center', backgroundSize: 'cover' }} variant="top" src="https://live.staticflickr.com/65535/52684489166_dcf13aa081_c.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>La Ventanita Menu</Card.Title>
                            <Card.Text>
                                Diseño de Menú para restaurante La Ventanita.
                            </Card.Text>
                            <Button className='btn-cards'>Ver Diseño</Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img style={{ height: '10rem', backgroundPosition: 'center', backgroundSize: 'cover' }} variant="top" src="https://live.staticflickr.com/65535/52684771619_f6055b7374_m.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>Expo Rock 2014 Flyer</Card.Title>
                            <Card.Text>
                                Diseño de Afiche para el evento Expo Rock 2014.
                            </Card.Text>
                            <Button className='btn-cards'>Ver Diseño</Button>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img style={{ height: '10rem', backgroundPosition: 'center', backgroundSize: 'cover' }} variant="top" src="https://live.staticflickr.com/65535/52684489176_e580bc3403_m.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>Flyer Skid Row Costa Rica</Card.Title>
                            <Card.Text>
                                Diseño oficial de Flyer para el concierto de Skid Row en Costa Rica.
                            </Card.Text>
                            <Button className='btn-cards'>Ver Diseño</Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img style={{ height: '10rem', backgroundPosition: 'center', backgroundSize: 'cover' }} variant="top" src="https://live.staticflickr.com/65535/52684987513_857f96e9cf_n.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>Flyer Skid Row Honduras</Card.Title>
                            <Card.Text>
                                Diseño oficial de Flyer para el concierto de Skid Row en Honduras.
                            </Card.Text>
                            <Button className='btn-cards'>Ver Diseño</Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img style={{ height: '10rem', backgroundPosition: 'center', backgroundSize: 'cover' }} variant="top" src="https://scontent.fsjo3-1.fna.fbcdn.net/v/t39.30808-6/224944323_476562363750358_6336776101076210894_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=e3f864&_nc_ohc=Vtf6WezBJA8AX_RQ7i2&_nc_ht=scontent.fsjo3-1.fna&oh=00_AfAHThoU3XMtOWW_O6SXc9hg9NUijAvevDUQpucZWhxuVg&oe=63EE4D91" />
                        <Card.Body>
                            <Card.Title className='text-white '>Crónica Rock</Card.Title>
                            <Card.Text>
                                Rediseño de Imagen y Logo para redes sociales y sitio web de Crónica Rock.
                            </Card.Text>
                            <Button className='btn-cards'>Ver Diseño</Button>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}