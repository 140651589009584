import React from 'react';

export const Footer = () => {
    return (
        <>
            <div className="container-fluid bg-dark border-top m-0 p-0">
                <footer>
                    <div className='d-flex justify-content-center flex-column'>
                        <div className='d-flex justify-content-center'>
                        <ul className="nav">
                        <li className="nav-item m-3"><a href={'https://github.com/danieljimenezcr'}><i class="fa-brands fa-github-alt footer-icons"></i></a></li>
                        <li className="nav-item m-3"><a href={'https://www.linkedin.com/in/danieljimenezchaverri'}><i class="fa-brands fa-linkedin-in footer-icons"></i></a></li>
                        <li className="nav-item m-3"><a href={'https://www.youtube.com/@danieljimenezchaverri/videos'}><i class="fa-brands fa-youtube footer-icons"></i></a></li>
                        <li className="nav-item m-3"><a href={'https://www.instagram.com/danieljimenezch/'}><i class="fa-brands fa-instagram footer-icons"></i></a></li>
                        <li className="nav-item m-3"><a href={'mailto:hello@daniel-jimenez.dev'}><i class="fa-solid fa-envelope footer-icons"></i></a></li>
                    </ul>
                        </div>
                        <div className='d-flex justify-content-center'>
                        <p className="mb-0 text-light mb-4">© Copyright 2023.  Made by Daniel Jiménez</p>
                        </div>
                    </div>
                    

                    
                </footer>
            </div>
        </>

    )
}