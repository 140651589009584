import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const ModalProyecto = (props)=> {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className='btn-cards' variant="primary" onClick={handleShow}>
        Live Demo
      </Button>

      <Modal show={show} onHide={handleClose} {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title>En Progreso</Modal.Title>
        </Modal.Header>
        <Modal.Body>Aún me encuentro trabajando en este proyecto, una vez finalizado estaré actualizando este enlace.</Modal.Body>
        <Modal.Footer>
          <Button className='btn-cards text-white' variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
