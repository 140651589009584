import React from 'react';
import profilePic from '../../src/img/profile-pic.png';
export const Hero = () => {
    return (
        <>
        <div class="hero-container text-secondary px-4 py-5 text-center">
                <img class="d-block mx-auto mb-4 profile-picture border-gradient border-gradient-purple" src={profilePic} alt="Profile" width="200" height="200" />
                <h1 class="display-5 fw-bold">Daniel Jiménez</h1>
                <h4>Un poco sobre mí</h4>
                <div class="col-lg-6 mx-auto">
                    <p class="lead mb-4">Fullstack Developer, especializado en Front-End y productor audiovisual, creador de <a href={'https://cronicarock.com'} target={'_blank'} className="text-primary" >Crónica Rock</a>, el segundo medio de comunicación Rock y Metal más grande de Centroamérica, también de <a href={'https://raccoonhosting.com'} target={'_blank'} className="text-primary" >Raccoon Hosting</a>, un negocio personal en donde vendo servicios de Web Hosting y Registro de Dominios.</p>
                    <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    <ul class="nav col-12 justify-content-center">
                        <li class="nav-item"><a href={'https://github.com/danieljimenezcr'}><i class="fa-brands fa-github-alt hero-icons mx-2"></i></a></li>
                        <li class="nav-item"><a href={'https://www.linkedin.com/in/danieljimenezchaverri'}><i class="fa-brands fa-linkedin-in hero-icons mx-2"></i></a></li>
                        <li class="nav-item"><a href={'https://www.youtube.com/@danieljimenezchaverri/videos'}><i class="fa-brands fa-youtube hero-icons mx-2"></i></a></li>
                        <li class="nav-item"><a href={'https://www.instagram.com/danieljimenezch/'}><i class="fa-brands fa-instagram hero-icons mx-2"></i></a></li>
                        <li class="nav-item"><a href={'mailto:hello@daniel-jimenez.dev'}><i class="fa-solid fa-envelope hero-icons mx-2"></i></a></li>
                    </ul>
                    </div>
                </div>
            </div>
        </>
    )

}