import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export const VideoPortfolio = () => {
    return (
        <>
            <div className='row d-flex justify-content-center'>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className='bg-dark border-0 portfolio-card'>
                        <Card.Img variant="top" src="https://live.staticflickr.com/65535/52656638916_5e21f9be11_b.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>La Ventanita Bowl</Card.Title>
                            <Card.Text>
                                Anuncio para redes sociales aplicando la técnica de Motion Graphics en After Effects
                            </Card.Text>
                            <Button className='btn-cards' target='_blank' href='https://www.youtube.com/watch?v=3Oq1EPrzlBI'>Ver Video</Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img variant="top" src="https://live.staticflickr.com/65535/52656154117_0dbc41f251_b.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>La Ventanita Ad</Card.Title>
                            <Card.Text>
                                Producción de spot publicitarion para La Ventanita.
                            </Card.Text>
                            <Button className='btn-cards' target='_blank' href='https://www.youtube.com/watch?v=2TWADexfQk8'>Ver Video</Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img variant="top" src="https://live.staticflickr.com/65535/52656638961_a9b8c66caa_b.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>Café Olé</Card.Title>
                            <Card.Text>
                                Práctica de Anuncio utilizando la técnica de grabación en Chroma y animación en After Effects.
                            </Card.Text>
                            <Button className='btn-cards' target='_blank' href='https://www.youtube.com/watch?v=ZaYjkoEYCOw'>Ver Video</Button>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img variant="top" src="https://live.staticflickr.com/65535/52657084940_00856cc585_b.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title card-title'>Raccoon Hosting Animation</Card.Title>
                            <Card.Text>
                                Animación en 2D usando la técnica de Motion Graphics en After Effects.
                            </Card.Text>
                            <Button className='btn-cards' target='_blank' href='https://www.youtube.com/watch?v=rXumjtKUtNo'>Ver Video</Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img variant="top" src="https://live.staticflickr.com/65535/52657084915_783d8b2201_b.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>Entrevista Adrián</Card.Title>
                            <Card.Text>
                                Producción de entrevista para Adrián campeón de Costa Rica en el juego FIFA.
                            </Card.Text>
                            <Button className='btn-cards' target='_blank' href='https://www.youtube.com/watch?v=VOc3OI9LUSY&t=44s'>Ver Video</Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img  variant="top" src="https://scontent.fsjo3-1.fna.fbcdn.net/v/t31.18172-8/1495201_576577909089696_720017526_o.jpg?_nc_cat=101&ccb=1-7&_nc_sid=cdbe9c&_nc_ohc=qbI9Lh_hfiQAX-AVG63&_nc_ht=scontent.fsjo3-1.fna&oh=00_AfAnLcutevT8pwE3cD3nDwhH6VhUnVc-gO1E4QCNcoVWwQ&oe=640FC7B9" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>Fotografía Crónica Rock</Card.Title>
                            <Card.Text>
                                Fotografía para el medio y sitio web Crónica Rock.
                            </Card.Text>
                            <Button className='btn-cards' target='_blank' href='https://www.facebook.com/media/set/?set=a.575617362519084&type=3'>Ver Galeria</Button>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )

}