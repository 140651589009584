import React from 'react';
export const Skills = () => {
    return (
        <div className='container-fluid bg-light pt-5' id='about-me'>
        <div className='skills-information'>
            <h2 className='text-center text-dark display-5 fw-bold'>Sobre Mi</h2>
            <p className='text-center text-dark'>
                Te cuento más sobre mí, skills, y conocimientos que tengo.
            </p>
        </div>
        <div className="container mb-5 col-xxl-8 px-4 ">
            <div className="row flex-lg-row-reverse g-5">
            <div className="col-lg-6 ">
                    <h2 className="display-6 fw-bold  lh-1 mb-3 text-dark">Skills</h2>
                    <div className='d-flex flex-wrap skills-container'>
                        <div className='skills-tags text-light'>HTML</div>
                        <div className='skills-tags text-light'>CSS</div>
                        <div className='skills-tags text-light'>JavaScript</div>
                        <div className='skills-tags text-light'>Python</div>
                        <div className='skills-tags text-light'>Bootstrap</div>
                        <div className='skills-tags text-light'>React</div>
                        <div className='skills-tags text-light'>SQL Basics</div>
                        <div className='skills-tags text-light'>Git/Github</div>
                        <div className='skills-tags text-light'>Photoshop</div>
                        <div className='skills-tags text-light'>Illustrator</div>
                        <div className='skills-tags text-light'>After Effects</div>
                        <div className='skills-tags text-light'>Premiere Pro</div>
                    </div>
                    
                </div>
                <div className="col-lg-6 mb-5">
                    <h2 className="display-6 fw-bold lh-1 mb-3 text-dark">Conóceme</h2>
                    <p className='text-dark'><span>Soy Daniel.</span> Apasionado por la tecnología, me gradué del Técnico de Producción Audiovisual de la Universidad Latina, llevé un Bootcamp de Fullstack Software Developer en 4Geeks Academy, y el de Programación de Oracle Next Education.</p>
                    
                    <p className='text-dark'>Sigo aprendiendo sobre React, y otras tecnologías, busco una oportunidad laboral en donde pueda aprender, crecer y contribuir, si encajo para el perfil que anda buscando, no dude en contactarme.</p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                        <button type="button" className="btn btn-cards text-light btn-lg px-4 me-md-2"> <a href={"#contact"} className="text-white">Contáctame</a> </button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )

}