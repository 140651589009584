import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";

// npm i @emailjs/browser

const Contact = () => {
  const form = useRef();
  const [status, setStatus] = useState('')
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9h655ji",
        "template_3g57ez4",
        form.current,
        "dd__BIvTJXoPHR7yE"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
           // Clears form/inputs after button is Selected
          form.current.reset();
        },
        setStatus('SUCCESS'),
        (error) => {
          console.log(error.text);
        }
      );
  };
  useEffect(() => {
    if(status === 'SUCCESS'){
      setTimeout(() => {
        setStatus('');
      },2000)
    }
  }, [status]);

  return (
    <>
    
    <div className="container-fluid d-flex justify-content-center bg-light p-5" id="contact">
      
      <StyledContactForm>
      
      <h2 className='text-center text-dark display-5 fw-bold'>Contáctame</h2>
      {status && renderAlert()}
      <form ref={form} onSubmit={sendEmail}>
        <label>Nombre</label>
        <input type="text" name="user_name" required />
        <label>Email</label>
        <input type="email" name="user_email" required/>
        <label>Mensaje</label>
        <textarea name="message" required />
        <input type="submit" value="Enviar" />
      </form>
    </StyledContactForm>
    </div>
    </>
  );
};

const renderAlert = ()=>(
  <div class="alert alert-success" role="alert">
  Tu mensaje fue enviado correctamente
</div>
)

export default Contact;

// Styles
const StyledContactForm = styled.div`
  width: 400px;
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    label {
      margin-top: 1rem;
    }
    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
      color: white;
      border: none;
    }
    input[type="submit"]:hover {
      background-image: linear-gradient(to bottom, #00c6fb 0%, #005bea 100%);
      
    }
  }
`;