import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import  {ModalProyecto} from './modalNotFinished';

export const WebPortfolio = () => {
    return (
        <>
            <div className='row d-flex justify-content-center'>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img variant="top" src="https://live.staticflickr.com/65535/52669492136_bcb2b54143_h.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>Fit Central</Card.Title>
                            <Card.Text>
                                Web app donde los usuarios podrán comprar una rutina de ejercicio.
                            </Card.Text>
                            <Button className='me-2 btn-cards'><a className='text-white' target={'_blank'} href={''}>Repositorio</a></Button>
                            <ModalProyecto/>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card" >
                        <Card.Img variant="top" src="https://live.staticflickr.com/65535/52669508041_565f4cf426_h.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>Star Wars Blog with React</Card.Title>
                            <Card.Text>
                                Web realizada con React donde se consume la API de Swapi para mostrar contenido de StarWars.
                            </Card.Text>
                            <Button className='me-2 btn-cards'><a className='text-white' target={'_blank'} href={'https://github.com/danieljimenezcr/Starwars-Blog-Reading-List-with-React'}>Repositorio</a></Button>
                            <Button className='btn-cards'><a className='text-white' target={'_blank'} href={'https://sample-service-name-4j18.onrender.com/'}>Live Demo</a></Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img variant="top" src="https://live.staticflickr.com/65535/52670008253_9b96668f25_h.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>ToDo List with React</Card.Title>
                            <Card.Text>
                                Aplicación Web de Lista de Tareas, donde se utiliza FETCH para consumor un API.
                            </Card.Text>
                            <Button className='me-2 btn-cards'><a className='text-white' target={'_blank'} href={'https://github.com/danieljimenezcr/ToDo-List-With-React-and-FETCH-API'}>Repositorio</a></Button>
                            <Button className='btn-cards'><a className='text-white' target={'_blank'} href={'https://to-do-list-with-react-and-fetch-api.vercel.app/'}>Live Demo</a></Button>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img variant="top" src="https://live.staticflickr.com/65535/52669795599_1afb0ac738_h.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>Batatabit Web</Card.Title>
                            <Card.Text>
                                Sitio Web desarrollado en HTML y CSS para el curso de Mobile First de Platzi.
                            </Card.Text>
                            <Button className='me-2 btn-cards'><a className='text-white' target={'_blank'} href={'https://github.com/danieljimenezcr/Batabit-Website'}>Repositorio</a></Button>
                            <Button className='btn-cards'><a className='text-white' target={'_blank'} href={'https://danieljimenezcr.github.io/Batabit-Website/'}>Live Demo</a></Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className="bg-dark border-0 portfolio-card">
                        <Card.Img variant="top" src="https://live.staticflickr.com/65535/52669944705_a4236188bf_h.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>Apeperia</Card.Title>
                            <Card.Text>
                                Sitio web en HTML y CSS desarrollado en el curso de Oracle Next Education..
                            </Card.Text>
                            <Button className='me-2 btn-cards'><a className='text-white' target={'_blank'} href={'https://github.com/danieljimenezcr/Apeperia'}>Repositorio</a></Button>
                            <Button className='btn-cards'><a className='text-white' target={'_blank'} href={'https://danieljimenezcr.github.io/Apeperia/'}>Live Demo</a></Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-sm-12 col-md-4 col-xl-3 d-flex justify-content-center mb-5'>
                    <Card style={{ width: '18rem' }} className='bg-dark border-0 portfolio-card' >
                        <Card.Img variant="top" src="https://live.staticflickr.com/65535/52670008213_0e7bfe8856_h.jpg" />
                        <Card.Body>
                            <Card.Title className='text-white card-title'>Rock EDM Fest</Card.Title>
                            <Card.Text>
                                Sitio web en HTML y CSS para el curso de Desarrollo Web Completo de UDEMY..
                            </Card.Text>
                            <Button className='me-2 btn-cards'><a className='text-white' target={'_blank'} href={'https://github.com/danieljimenezcr/rockfestival-website'}>Repositorio</a></Button>
                            <Button className='btn-cards'><a className='text-white' target={'_blank'} href={'https://danieljimenezcr.github.io/rockfestival-website/'}>Live Demo</a></Button>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}