import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


export const NavbarMenu = () => {
    return (
        <>
      <Navbar variant="dark" expand="lg" className='navMenu'>
      <Container>
        <Navbar.Brand href="#home"><h2>Daniel Jimenez</h2></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto mx-5 d-flex align-items-right">
            <Nav.Link className='navbar-items' href="#home">Inicio</Nav.Link>
            <Nav.Link className='navbar-items' href="#about-me">Sobre Mí</Nav.Link>
            <Nav.Link className='navbar-items' href="#portfolio">Portafolio</Nav.Link>
            {/* <Nav.Link className='navbar-items' href="#projects">Proyectos</Nav.Link> */}
            <Nav.Link className='navbar-items' href="#contact">Contacto</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
        </>
    )

}