import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { WebPortfolio } from './webPortfolio';
import { VideoPortfolio } from './videoPortfolio';
import { GraphicPortfolio } from './graphicPortfolio';


export const Portfolio = () => {
    const [key, setKey] = useState('development');
    return (
        <div id='portfolio'>
            <h2 className='text-center text-dark display-5 fw-bold'>Portafolio</h2>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 d-flex justify-content-center border-0 p-3 tabs-mobile"
            >
                <Tab eventKey="development" title="Desarrollo">
                    <WebPortfolio/>
                </Tab>
                <Tab eventKey="video" title="Producción Audiovisual">
                    <VideoPortfolio/>
                </Tab>
                <Tab eventKey="graphic" title="Diseño Gráfico">
                    <GraphicPortfolio/>
                </Tab>
            </Tabs>
        </div>
    )
}