import React from 'react';
import { Hero } from './components/hero';
import {Skills} from './components/skills';
import { Portfolio } from './components/portfolio';
import Contact from './components/contact';


function Home() {
    return (
        <>
            <Hero/>
            <Skills/>
            <Portfolio/>
            <Contact/>
        </>

    )

}

export default Home;